<template>
  <div class="alarming">
    <h2 class="cm-title">正在告警</h2>
    <ul class="items" v-if="alarmRecords.length">
      <li class="item" v-for="(item, index) in alarmRecords" :key="index">
        <div class="content">
          <p class="title">
            {{ item.alarmName }}
            <em v-if="item.alarmAckStatus === 1">已处理</em>
          </p>
          <p class="time">{{ item.createTime }}</p>
        </div>
        <div class="status">
          <span :class="`dot-${iconLevel[item.alarmLevel]} large`">
            {{ nameTrans(item.alarmLevel, alarmLevel) }}
          </span>
        </div>
        <div class="btns">
          <button class="btn" @click="handleDetail(item)">
            <i class="iconfont icon-chakan" />查看详情
          </button>
          <button class="btn" @click="handleAlarm(item)">
            <i class="iconfont icon-chuli" />
            处理登记
          </button>
        </div>
      </li>
    </ul>
    <gc-empty v-else />

    <alarm-handle-dialog
      :show.sync="showHandleDialog"
      :alarmId="alarmId"
      @ops-success="opsSuccess"
    ></alarm-handle-dialog>
  </div>
</template>

<script>
import { apiGetAlarmRecordsList } from "@/api/alarm";
import { nameTrans } from "@/utils";
import { mapMutations } from "vuex";
import AlarmHandleDialog from "@/components/AlarmHandleDialog/index.vue";
const iconLevel = {
  1: "success",
  2: "warning",
  3: "error",
};

export default {
  name: "Alarming",
  components: { AlarmHandleDialog },
  data() {
    return {
      alarmRecords: [],
      iconLevel,
      firstCategory: "",
      showHandleDialog: false,
      alarmId: "",
    };
  },
  computed: {
    // 告警等级
    alarmLevel() {
      return this.$store.getters.dataList?.alarmLevel || [];
    },
  },
  methods: {
    ...mapMutations({
      setUpdateAlarmRecords: "common/SET_UPDATE_ALARM_RECORDS",
    }),
    nameTrans,
    getAlarmRecords(firstCategory) {
      this.firstCategory = firstCategory;
      const params = {
        alarmSeq: 0,
        current: 1,
        size: 100,
      };
      if (firstCategory) params.firstCategory = firstCategory;
      apiGetAlarmRecordsList(params)
        .then(({ records = [] }) => {
          this.alarmRecords = records;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 查看详情
    handleDetail(row) {
      if (!this.$has("monitor:device:account:record:alarm"))
        return this.$message.error("您没有操作权限！");
      this.$router.push({
        name: "deviceDetail",
        query: {
          deviceId: row.deviceId,
          deviceType: row.deviceTypeId,
        },
        params: {
          name: "AlarmRecord",
        },
      });
    },
    // 点击处理
    handleAlarm(row) {
      if (!this.$has("monitor:device:account:record:alarm")) {
        this.$message.error("您没有操作权限！");
        return;
      }
      this.alarmId = row.alarmId;
      this.showHandleDialog = true;
    },

    opsSuccess() {
      this.setUpdateAlarmRecords(true); // 标记成功后进入告警记录页面更新列表
      this.getAlarmRecords(this.firstCategory);
    },
  },
};
</script>

<style scoped lang="scss">
.alarming {
  margin-top: 30px;
  margin-left: 5px;
}
.items {
  margin-top: 24px;
  min-height: 300px;
  max-height: calc(100vh - 650px);
  overflow: auto;
  @media (max-width: 1680px) {
    max-height: calc(100vh - 720px);
  }
  .item {
    margin-top: 24px;
    display: flex;
    &:first-of-type {
      margin-top: 0;
    }
    .content {
      width: 45%;
      color: #333;
      .title {
        max-width: 270px;
        font-size: 18px;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        em {
          font-size: 12px;
          margin-left: 6px;
          background: rgba(76, 107, 255, 0.18);
          border: 1px solid rgba(74, 105, 255, 0.4);
          border-radius: 2px;
          color: #4a69ff;
          display: inline-block;
          padding: 2px;
        }
        @media (max-width: 1680px) {
          font-size: 16px;
        }
      }
      .time {
        margin-top: 8px;
        opacity: 0.7;
      }
    }
    .status {
      width: 20%;
      display: flex;
      align-items: center;
    }
    .btns {
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn {
        @include flex-center;
        cursor: pointer;
        width: 120px;
        height: 32px;
        border-radius: 16px;
        background: #fff;
        border: 1px solid $base-color-theme;
        font-size: 12px;
        color: $base-color-theme;
        &.disabled {
          border: 1px solid rgba(77, 107, 255, 0.5);
          color: rgba(77, 107, 255, 0.5);
          cursor: not-allowed;
        }
        + .btn {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
